import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Fab, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import StarsIcon from "@material-ui/icons/Stars";

import ItemVideoGameDetail from "./itemVideoGameDetail.js";
import ItemVideoGameRelatedDetail from "./itemVideoGameRelatedDetail.js";
import { API, Auth, Analytics } from 'aws-amplify';
import ViewListIcon from "@material-ui/icons/ViewList";

import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

const apiName = 'MyAPIGatewayAPI';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  root: {
    paddingBottom: 20,
    textAlign: 'center'
  },
  title: {
    paddingTop: theme.spacing(1),
    paddingRight: 20
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  absolute: {
    position: 'fixed', /* instead of absolute */
    bottom: 10,
    right: 10,
  },
}));

function VideoGame(props) {
  const classes = useStyles();
  const [user, setUser] = useState({});
  const [listCounter, setListCounter] = useState(0);
  const [showLoading, setShowLoading] = useState(false);
  const [videoGameDetail, setVideoGameDetail] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    fetchItems(props.match.params.videoGameId);
  }, []);

  const [itemsSR, setItemsSR] = useState([]);
  
  const fetchItems = async (myVideoGameId) => {
    try {
      setShowLoading(true);
      const data = await Auth.currentUserPoolUser();
      const userInfo = { username: data.username, ...data.attributes, };
      setUser(userInfo);
      let path = '/video-game/get/'+myVideoGameId;
      console.log(path);
      let myInit = {
          headers: {},
          response: true,
      }
      API.get(apiName, path, myInit).then(response => {
          /*response.data.videoGames.forEach(function(element) {
            //console.log(element);
            setItems(items => [...items, element])
          });*/
          console.log(response);
          setShowLoading(false);
          /*setLast(response.data.last);

          if (response.data.last>0)
            setShowMore(true);
          else
            setShowMore(false);*/
          setVideoGameDetail(response.data.videoGame);
          if (response.data.similar_recommendations.length>0)
            setItemsSR(response.data.similar_recommendations)
      }).catch(error => {
          console.log(error.response)
      });
      API.get(apiName, "/video-game/list/counter/"+userInfo.sub, myInit).then(response => {
        console.log(response.data);
        setListCounter(response.data.list_counter);
        /*if (response.data.recommendations.length>0)
          setItemsR(response.data.recommendations)
        */
      }).catch(error => {
          console.log(error.response)
      });
    } catch (err) { console.log('error: ', err) }
  };

  const handleAdd = event => {
    try {
      console.log("onAdd Item");
      console.log(user);
      let path = "/video-game/user/add"
      let myInit = { // OPTIONAL
        headers: {}, // OPTIONAL
        body: {
          user: user,
          item: event
        },
        response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      }
      API.post(apiName, path, myInit).then(response => {
          console.log(response);
          setOpen(true);
          setListCounter(response.data.list_counter);
          //setItemsR(response.data.recomendations);
          setItemsSR(response.data.similarRecomendations);
          Analytics.updateEndpoint({
            address: user.email,
            attributes: {
                // Custom attributes that your app reports to Amazon Pinpoint. You can use these attributes as selection criteria when you create a segment.
                recomendations: response.data.recomendationIds,
            }
        }).then(() => {
        });

      }).catch(error => {
          console.log(error.response)
      });
      console.log(event);
    } catch (err) { console.log('error: ', err) }
  };

  const handleClickTitle = event => {
    setVideoGameDetail(event);
    setItemsSR([]);
    fetchItems(event.videoGameId);
    window.scrollTo(0, 0);
  };

  return (
    <div className="IndexVideoGames">

      <div className={classes.root}>
        <div className={classes.wrapper}>
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            className={classes.title}
          >
            Detalle de Videojuego
          </Typography>
        </div>
        <div className={classes.wrapper}>
          <Fab
            variant="extended"
            color="default"
            aria-label="add"
            className={classes.margin}
            component={Link}
            to="/"
          >
            <ViewListIcon className={classes.extendedIcon} />
            Explorar videojuegos
          </Fab>
        </div>
      </div>

      <div>

        <div style={videoGameDetail=="" ? { marginBottom: 20, display: 'none' } : { marginBottom: 20 }}>
          <ItemVideoGameDetail key={videoGameDetail.videoGameId}
                    item={videoGameDetail}
                    onAdd={handleAdd}
                    date={videoGameDetail.dateAt} />
        </div>

        <div className={classes.root}>
          <div className={classes.wrapper} style={{ marginTop: 40, marginBottom: 40 }}>
              <div style={{ textAlign: 'center' }}>
                <img src={process.env.PUBLIC_URL + '../images/loading.gif'} alt="loading..." style={showLoading ? {} : { display: 'none' }} />
              </div>
            </div>
        </div>

        <div style={itemsSR.length>0 ? { marginBottom: 20 } : { marginBottom: 20, display: 'none' }}>
        <Typography><strong>Videojuegos relacionados</strong></Typography>
        <Grid container spacing={3}>
              {itemsSR.map(item => (
              <ItemVideoGameRelatedDetail
                  key={item.videoGameId}
                  item={item}
                  onAdd={handleAdd}
                  onClickTitle={handleClickTitle}
                  date={item.dateAt}
              />
              ))}
          </Grid>
        </div>

      </div>
      
      <Fab
        variant="extended"
        color="primary"
        className={classes.absolute}
        component={Link}
        to="/myList"
      >
        <StarsIcon className={classes.extendedIcon} />
        Ver/Pedir lista de Videojuegos ( <strong>{ listCounter }</strong> )
      </Fab>

      <Snackbar anchorOrigin={{ horizontal: 'center', vertical: 'top' }} open={open} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          ¡El videojuego fue agregado a la lista de favoritos!
        </Alert>
      </Snackbar>

    </div>
  );
}

export default VideoGame;