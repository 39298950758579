import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Fab, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import ViewListIcon from "@material-ui/icons/ViewList";
import SendIcon from "@material-ui/icons/Send";
import { API, Auth, Analytics } from 'aws-amplify';
import ItemVideoGameAdded from "./itemVideoGameAdded.js";

import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

const apiName = 'MyAPIGatewayAPI';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  root: {
    paddingBottom: 20,
    textAlign: 'center'
  },
  title: {
    paddingTop: theme.spacing(1),
    paddingRight: 20
  }
}));

function IndexVideoGames() {
  const classes = useStyles();
  const [user, setUser] = useState({});
  const [showLoading, setShowLoading] = useState(false);
  const [showSend, setShowSend] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");

  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    fetchItems();
  }, []);

  const [items, setItems] = useState([]);

  const fetchItems = async () => {
    try {
      setShowLoading(true);
      const data = await Auth.currentUserPoolUser();
      const userInfo = { username: data.username, ...data.attributes, };
      setUser(userInfo);
      let path = '/video-game/list/user/'+userInfo.sub;
      let myInit = { 
          headers: {},
          response: true,
      }
      API.get(apiName, path, myInit).then(response => {
          console.log(response);
          if (response.data.videoGames.length>0)
            setShowSend(true);
          setItems(response.data.videoGames);
          setShowLoading(false);
      }).catch(error => {
          console.log(error.response)
      });
    } catch (err) { console.log('error: ', err) }
  };

  const handleDelete = event => {
    try {
      console.log("onAdd Item");
      console.log(user);
      let path = "/video-game/user/remove"
      let myInit = { // OPTIONAL
        headers: {}, // OPTIONAL
        body: {
          user: user,
          item: event
        },
        response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      }
      API.post(apiName, path, myInit).then(response => {
          console.log(response);
          setMessageAlert("¡El videojuego fue eliminado de la lista de favoritos!");
          setOpen(true);
          //setListCounter(response.data.list_counter);
          const new_items = items.filter(myitem => myitem.videoGameId !== event.videoGameId);
          if (new_items.length==0)
            setShowSend(false);
          setItems(new_items);
      }).catch(error => {
          console.log(error.response)
      });
      console.log(event);
    } catch (err) { console.log('error: ', err) }
  };
  
  const handleSendList = event => {
    try {
      setShowLoading(true);
      let countList = items.length;
      let path = "/video-game/user/list/send"
      let myInit = {
        headers: {},
        body: {
          user: user
        },
        response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      }
      API.post(apiName, path, myInit).then(response => {
          console.log(response);
          setMessageAlert("¡La lista de videojuegos fue enviada! :)");
          setOpen(true);
          setItems([]);
          setShowSend(false);
          let today = new Date();
          Analytics.record({ name: 'sendList', attributes: { countList: countList.toString(), year: today.getFullYear().toString(), month: (today.getMonth()+1).toString(), day: today.getDay().toString(), date: today.getDate().toString(), hour: today.getHours().toString() } }).then( (evt) => {
            console.log("Event Submitted" + JSON.stringify(evt));
          });
          setShowLoading(false);
      }).catch(error => {
          console.log(error.response)
      });
    } catch (err) { console.log('error: ', err) }
  }

  return (
    <div className="IndexVideoGames">
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            className={classes.title}
          >
            Mi Lista Videojuegos Favoritos
          </Typography>
        </div>

        <div className={classes.wrapper}>
          <Fab
            variant="extended"
            color="primary"
            aria-label="add"
            className={classes.margin}
            onClick={handleSendList}
            style={ showSend ? {} : { display: 'none' }}
          >
            <SendIcon className={classes.extendedIcon} />
            Pedir lista de videojuegos
          </Fab>
        </div>

        <div className={classes.wrapper}>
          <Fab
            variant="extended"
            color="default"
            aria-label="add"
            className={classes.margin}
            component={Link}
            to="/"
          >
            <ViewListIcon className={classes.extendedIcon} />
            Explorar videojuegos
          </Fab>
        </div>
      </div>

      <div style={{ marginTop: 20, marginBottom: 20, textAlign: 'center' }}>
            <img src={process.env.PUBLIC_URL + 'images/loading.gif'} alt="loading..." style={ showLoading ? {} : { display: 'none' }} />
      </div>
      
      <div>
        <Grid container spacing={3}>
            {items.map(item => (
            <ItemVideoGameAdded
                key={item.videoGameId}
                item={item}
                onDelete={handleDelete}
                date={item.dateAt}
            />
            ))}
        </Grid>
      </div>
    
      <Snackbar anchorOrigin={{ horizontal: 'center', vertical: 'top' }} open={open} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          {messageAlert}
        </Alert>
      </Snackbar>

    </div>
  );
}

export default IndexVideoGames;