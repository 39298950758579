import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import IndexVideoGames from "./components/indexVideoGames.js";
import IndexMyListVideoGames from "./components/indexMyListVideoGames.js";
import VideoGame from "./components/videoGame.js";
import Paper from "@material-ui/core/Paper";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Analytics from '@aws-amplify/analytics';
import { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
import { withAuthenticator } from 'aws-amplify-react';
Auth.configure(awsconfig);

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(5, 2)
  }
}));

const mapObj = f => obj =>
  Object.keys(obj).reduce((acc, key) => ({ ...acc, [key]: f(obj[key]) }), {});
const toArrayOfStrings = value => [`${value}`];
const mapToArrayOfStrings = mapObj(toArrayOfStrings);

function App() {
  const classes = useStyles();

  useEffect(() => {
    trackUserId();
  }, []);

  async function trackUserId() {
    try {
      const { attributes } = await Auth.currentAuthenticatedUser();
      const userAttributes = mapToArrayOfStrings(attributes);
      console.log("-----> " + JSON.stringify(userAttributes));
      Analytics.updateEndpoint({
        address: userAttributes.email[0],
        channelType: 'EMAIL',
        optOut: 'NONE',
        userId: userAttributes.sub[0],
        userAttributes,
      }).then(response => {
        console.log(response);
      }).catch(error => {
          console.log(error.response)
      });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="App">
      <Router>
        <React.Fragment>
          <CssBaseline />
          <Container
            maxWidth="lg"
            style={{ height: "100vh", paddingTop: 20 }}
          >
            <Paper className={classes.root}>
              <Switch>
                <Route path="/" exact component={IndexVideoGames} />
                <Route path="/myList" exact component={IndexMyListVideoGames} />
                <Route path="/videoGame/:videoGameId" exact component={VideoGame} />
              </Switch>
            </Paper>
          </Container>
        </React.Fragment>
      </Router>
    </div>
  );
}

const signUpConfig = {
  //header: 'Welcome!',
  defaultCountryCode: '52',
  hideDefaults: true,
  signUpFields: [
    {
      label: 'Username',
      key: 'username',
      required: true,
      displayOrder: 1,
      type: 'string',
    },
    {
      label: 'Password',
      key: 'password',
      required: true,
      displayOrder: 2,
      type: 'password',
    },
    {
      label: 'Email',
      key: 'email',
      required: true,
      displayOrder: 3,
      type: 'email',
    },
    {
      label: 'Name',
      key: 'name',
      required: true,
      displayOrder: 4,
      type: 'string',
    },
    /*{
      label: 'Family name',
      key: 'family_name',
      required: true,
      displayOrder: 5,
      type: 'string',
    },*/
    {
      label: 'Phone number *',
      key: 'phone_number',
      required: false,
      displayOrder: 5,
      type: 'string',
    }
  ]
};


export default withAuthenticator(App, {includeGreetings: true, signUpConfig: signUpConfig });