// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:25bcfe41-6ca3-42c3-99e8-2de1b32bb23e",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_8ujKk7mgM",
    "aws_user_pools_web_client_id": "2sjfto6t8nbdfuo9llgurd9ddi",
    "oauth": {},
    "aws_mobile_analytics_app_id": "f5f349cdf0ab4082a168d3b0e2407d64",
    "aws_mobile_analytics_app_region": "us-east-1"
};


export default awsmobile;
