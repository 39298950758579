import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  Grid,
  Card,
  Typography,
} from "@material-ui/core";

import Chip from '@material-ui/core/Chip';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import StarIcon from "@material-ui/icons/Star";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  itemTodo: {
    //marginTop: theme.spacing(1)
  },
  details: {
    flexDirection: 'column',
  },

  content: {
    flex: '1 0 auto',
  },
  cover: {
    display: 'block',
    width: 60,
    height: 80
  }
}));



let bucket = "https://s3.amazonaws.com/images.video-games-list.kabits.com";

function ItemVideoGame(props) {
  //console.log(props);
  const classes = useStyles();

  return (
    <Grid item sm={6} md={3} xs={12}>
        <Card className={classes.itemTodo} style={{ backgroundColor: '#fff3cd', borderColor: '#ffeeba' }}>
            <Grid container>
            <Grid item sm={2} xs={2} md={3}>
                <CardMedia
                    className={classes.cover}
                    image={ bucket+props.item.image }
                    title="Live from space album cover"
                />
            </Grid>
            <Grid item sm={10} xs={10} md={9}>
                <div className={classes.details}>
                <CardContent className={classes.content}>
                
                <Typography onClick={() => props.onClickTitle(props.item)} variant="subtitle2" style={{ paddingBottom: 10 }} component={Link} to={ "/videoGame/"+props.item.videoGameId } color="inherit">
                        {props.item.name} - <strong>{props.item.platform}</strong>, <strong>{props.item.genre}</strong>
                    </Typography>
                        <IconButton
                            aria-label="Add List"
                            style={{ color: "#28BC45", fontSize: 12 }}
                            onClick={() => props.onAdd(props.item)}
                        >
                            <StarIcon /> Agregar a lista
                        </IconButton>
                    
                </CardContent>
                </div>
            </Grid>
        </Grid>
        </Card>

    </Grid>
  );
}

export default ItemVideoGame;