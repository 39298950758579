import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  Grid,
  Card,
  Typography,
} from "@material-ui/core";

import Chip from '@material-ui/core/Chip';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import StarIcon from "@material-ui/icons/Star";
import DeleteIcon from '@material-ui/icons/Delete';
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  itemTodo: {
    marginTop: theme.spacing(1)
  },
  details: {
    flexDirection: 'column',
  },

  content: {
    flex: '1 0 auto',
  },
  cover: {
    display: 'block',
    width: 96,
    height: 130
  },
}));

let bucket = "https://s3.amazonaws.com/images.video-games-list.kabits.com";

function ItemVideoGameAdded(props) {
  //console.log(props);
  const classes = useStyles();

  return (
    <Grid item sm={6} md={4} xs={12}>
        <Card className={classes.itemTodo}>
            <Grid container spacing={3}>
            <Grid item sm={4} xs={3} md={4}>
                <CardMedia
                    className={classes.cover}
                    image={ bucket+props.item.image }
                    title="Live from space album cover"
                />
            </Grid>
            <Grid item sm={8} xs={9} md={8} container>
                <div className={classes.details}>
                <CardContent className={classes.content}>
                    <Typography variant="subtitle1" style={{ paddingBottom: 10 }} component={Link} to={ "/videoGame/"+props.item.videoGameId } color="inherit">
                        {props.item.name}
                    </Typography>
                    <div>
                      <Chip label={props.item.platform} variant="outlined" size="small" /> 
                      <Chip label={props.item.genre} variant="outlined" size="small" />
                    </div>
                    <div>
                        <IconButton
                            aria-label="Add List"
                            style={{ color: "#DC3545", fontSize: 12 }}
                            onClick={() => props.onDelete(props.item)}
                        >
                            <DeleteIcon /> Remover de lista
                        </IconButton>
                    </div>
                </CardContent>
                </div>
            </Grid>
        </Grid>
        </Card>
    </Grid>
  );
}

export default ItemVideoGameAdded;