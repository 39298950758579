import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  Grid,
  Card,
  Typography,
} from "@material-ui/core";

import Chip from '@material-ui/core/Chip';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import StarIcon from "@material-ui/icons/Star";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  itemTodo: {
    //marginTop: theme.spacing(1)
  },
  details: {
    flexDirection: 'column',
  },

  content: {
    flex: '1 0 auto',
  },
  cover: {
    display: 'block',
    width: 160,
    height: 240
  }
}));

let bucket = "https://s3.amazonaws.com/images.video-games-list.kabits.com";

function ItemVideoGame(props) {
  //console.log(props);
  const classes = useStyles();

  return (
    <Grid item sm={12} md={12} xs={12}>
        <Card className={classes.itemTodo}>
            <Grid container spacing={3}>
            <Grid item sm={4} xs={12} md={2}>
                <CardMedia
                    className={classes.cover}
                    image={ bucket+props.item.image }
                    title="Live from space album cover"
                />
            </Grid>
            <Grid item sm={8} xs={12} md={10} container>
                <div className={classes.details}>
                <CardContent className={classes.content}>                
                    <Typography variant="h6" style={{ paddingBottom: 10 }}>
                        {props.item.name}
                    </Typography>
                    <div style={{ marginTop:10 }}>
                        <Chip label={props.item.platform} variant="outlined" size="medium" /> 
                        <Chip label={props.item.genre} variant="outlined" size="medium" />
                    </div>
                    <div>
                        <IconButton
                            aria-label="Add List"
                            style={{ color: "#28BC45", fontSize: 12 }}
                            onClick={() => props.onAdd(props.item)}
                        >
                            <StarIcon /> Agregar a lista
                        </IconButton>
                    </div>
                </CardContent>
                </div>
            </Grid>
        </Grid>
        </Card>

    </Grid>
  );
}

export default ItemVideoGame;