import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Fab, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import StarsIcon from "@material-ui/icons/Stars";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ItemVideoGame from "./itemVideoGame.js";
import ItemVideoGameRelated from "./itemVideoGameRelated.js";
import { API, Auth, Analytics } from 'aws-amplify';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

const apiName = 'MyAPIGatewayAPI';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  },
  root: {

  },
  wrapper: {
    paddingBottom: 20,
    textAlign: 'center'
  },
  title: {
    paddingTop: theme.spacing(1),
    paddingRight: 20
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  absolute: {
    position: 'fixed', /* instead of absolute */
    bottom: 10,
    right: 10,
  },
}));

function IndexVideoGames() {
  const classes = useStyles();
  const [user, setUser] = useState({});
  const [listCounter, setListCounter] = useState(0);
  const [last, setLast] = useState(0);
  const [showLoading, setShowLoading] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  const [platform, setPlatform] = React.useState('');

  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleChangePlatform = event => {
    console.log(event.target.value);
    setItems([]);
    setLast(0);
    fetchItems(event.target.value);
  };

  useEffect(() => {
    fetchItems('');
  }, []);

  const [itemsR, setItemsR] = useState([]);
  const [itemsSR, setItemsSR] = useState([]);
  const [items, setItems] = useState([]);
  
  const fetchItems = async (myPlatform) => {
    try {
      let myLast = 0;
      if (platform!=myPlatform){
        setPlatform(myPlatform);
        setLast(0);
      }
      else{
        myLast = last;
      }
      setShowLoading(true);
      setShowMore(false);
      const data = await Auth.currentUserPoolUser();
      const userInfo = { username: data.username, ...data.attributes, };
      setUser(userInfo);
      let path = "";
      if (myPlatform!="")
        path = '/video-game/list-platform/'+myPlatform;
      else
        path = '/video-game/list';
      if (myLast>0)
        path = path + '/'+myLast;
      console.log(path);
      let myInit = {
          headers: {},
          response: true,
      }
      API.get(apiName, path, myInit).then(response => {
          response.data.videoGames.forEach(function(element) {
            //console.log(element);
            setItems(items => [...items, element])
          });
          console.log(response.data.last);
          setLast(response.data.last);
          setShowLoading(false);
          if (response.data.last>0)
            setShowMore(true);
          else
            setShowMore(false);
      }).catch(error => {
          console.log(error.response)
      });
      API.get(apiName, "/video-game/list/counter/"+userInfo.sub, myInit).then(response => {
        console.log(response.data);
        setListCounter(response.data.list_counter);
        if (response.data.recommendations.length>0)
          setItemsR(response.data.recommendations)
        if (response.data.similar_recommendations.length>0)
          setItemsSR(response.data.similar_recommendations)
      }).catch(error => {
          console.log(error.response)
      });
    } catch (err) { console.log('error: ', err) }
  };

  const handleAdd = event => {
    try {
      console.log("onAdd Item");
      console.log(user);
      let path = "/video-game/user/add"
      let myInit = { // OPTIONAL
        headers: {}, // OPTIONAL
        body: {
          user: user,
          item: event
        },
        response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      }
      API.post(apiName, path, myInit).then(response => {
          console.log(response);
          setOpen(true);
          setListCounter(response.data.list_counter);
          setItemsR(response.data.recomendations);
          setItemsSR(response.data.similarRecomendations);
          Analytics.updateEndpoint({
            address: user.email,
            attributes: {
                // Custom attributes that your app reports to Amazon Pinpoint. You can use these attributes as selection criteria when you create a segment.
                recomendations: response.data.recomendationIds,
            }
        }).then(() => {
        });

      }).catch(error => {
          console.log(error.response)
      });
      console.log(event);
    } catch (err) { console.log('error: ', err) }
  };

  const handleClickMore = event => {
    fetchItems(platform);
    console.log("More");
  };

  return (
    <div className="IndexVideoGames">

      <div className={classes.root}>
        <div className={classes.wrapper}>
          <Typography
            variant="h4"
            component="h1"
            gutterBottom
            className={classes.title}
          >
            Catálogo de Videojuegos
          </Typography>
          <Typography
            component="h1"
            gutterBottom
          >
            <b>Crea</b> y <b>pide</b> una lista de videojuegos que <b>te gustaría</b> que te enviemos.
          </Typography>
        </div>
      </div>
      <div>

      <div style={itemsR.length>0 ? { marginBottom: 10 } : { marginBottom: 10, display: 'none' }}>
        <Typography><strong>Recomendaciones para ti</strong></Typography>
        <Grid container spacing={3}>
              {itemsR.map(item => (
              <ItemVideoGameRelated
                  key={item.videoGameId}
                  item={item}
                  onAdd={handleAdd}
                  date={item.dateAt}
              />
              ))}
          </Grid>
        </div>

        <div className={classes.wrapper}>
        <FormControl variant="filled" className={classes.formControl}>
          <InputLabel ref={inputLabel} id="demo-simple-select-outlined-label">
            Plataforma
          </InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={platform}
            onChange={handleChangePlatform}
            labelWidth={labelWidth}
          >
            <MenuItem value="">
              <em>Todas</em>
            </MenuItem>
            <MenuItem value={"ps4"}>PlayStation 4</MenuItem>
            <MenuItem value={"switch"}>Switch</MenuItem>
            <MenuItem value={"xbox-one"}>Xbox One</MenuItem>
          </Select>
        </FormControl>
        </div>

        <Grid container spacing={3} style={{ marginBottom: 10 }}>
            {items.map(item => (
            <ItemVideoGame
                key={item.videoGameId}
                item={item}
                onAdd={handleAdd}
                date={item.dateAt}
            />
            ))}
        </Grid>


        <div className={classes.root}>

        <div className={classes.wrapper} style={{ marginTop: 40, marginBottom: 40 }}>
          <div style={{ textAlign: 'center' }}>
            <img src={process.env.PUBLIC_URL + 'images/loading.gif'} alt="loading..." style={showLoading ? {} : { display: 'none' }} />
          </div>
            <Fab
                variant="extended"
                color="default"
                aria-label="add"
                size="small"
                onClick={handleClickMore}
                //style={{ width: 160 }}
                style={showMore ? { width: 160 } : { width: 160, display: 'none' }}
            >
                <ExpandMoreIcon className={classes.extendedIcon} />
                Ver más
            </Fab>
            </div>
        </div>

        <div style={itemsSR.length>0 ? { marginBottom: 10 } : { marginBottom: 10, display: 'none' }}>
        <Typography><strong>Recomendados en base a tu último agregado</strong></Typography>
        <Grid container spacing={3}>
              {itemsSR.map(item => (
              <ItemVideoGameRelated
                  key={item.videoGameId}
                  item={item}
                  onAdd={handleAdd}
                  date={item.dateAt}
              />
              ))}
          </Grid>
        </div>

      </div>
        
      <Fab
        variant="extended"
        color="primary"
        className={classes.absolute}
        component={Link}
        to="/myList"
      >
        <StarsIcon className={classes.extendedIcon} />
        Ver/Pedir lista de Videojuegos ( <strong>{ listCounter }</strong> )
      </Fab>

      <Snackbar anchorOrigin={{ horizontal: 'center', vertical: 'top' }} open={open} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          ¡El videojuego fue agregado a la lista de favoritos!
        </Alert>
      </Snackbar>

    </div>
  );
}

export default IndexVideoGames;